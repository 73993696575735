import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import TwoColumn from "../../../components/two-column/two-column";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const BusinessCreditSolutions = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-line-of-credit/hero-business-credit-solutions-03162023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-line-of-credit/hero-business-credit-solutions-03162023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-line-of-credit/hero-business-credit-solutions-03162023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-line-of-credit/hero-business-credit-solutions-03162023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-line-of-credit/hero-business-credit-solutions-03162023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-line-of-credit/hero-business-credit-solutions-03162023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-line-of-credit/hero-business-credit-solutions-03162023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      linesOfCreditImage: file(relativePath: { eq: "thumbnail-business-lines-of-credit-102723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 600, height: 400, layout: CONSTRAINED)
        }
      }
      commercialRealEstateImage: file(relativePath: { eq: "thumbnail-commercial-real-estate-financing-102723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 600, height: 400, layout: CONSTRAINED)
        }
      }
      businessTermLoansImage: file(relativePath: { eq: "thumbnail-business-term-loans-102723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 600, height: 400, layout: CONSTRAINED)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "business-credit-solutions-hero",
    ...getHeroImgVariables(imgData),
    altText: "Business owner sitting at cafe bar stool",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "We take your business financing personally"
          }
        },
        {
          id: 2,
          button: {
            id: "credit-solutions-hero-cta",
            class: "btn-white",
            text: "Contact Sales",
            url: "/business-banking/business-line-of-credit/contact-us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Credit Solutions"
    }
  ];

  const ctaButtonData = {
    id: "sb-credit-cta-button",
    type: "anchor",
    externalLink: false,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto",
    text: "Contact Sales",
    showIcon: false,
    url: "/business-banking/business-line-of-credit/contact-us"
  };

  const SEOData = {
    title: "Small Business Credit Solutions",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Small Business Credit Solutions"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "WaFd Bank puts your business first. Whether you need a business line of credit or commercial real estate loan, we can help. Learn more here."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-line-of-credit"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-business-credit-solutions-03162023.jpg"
      }
    ]
  };

  const linesOfCreditData = {
    sectionClass: "bg-light",
    sectionId: "business-lines-of-credit-section",
    columns: [
      {
        id: 1,
        colClass: "col-sm-12 col-md-6 mb-4 mb-md-0",
        content: {
          components: [
            {
              id: 1,
              heading: {
                text: "Business Lines of Credit"
              }
            },
            {
              id: 2,
              paragraph: {
                text: "If you need to borrow funds to support cyclical or seasonal cash flow, Business Lines of Credit are often the right solution."
              }
            },
            {
              id: 3,
              list: {
                items: [
                  {
                    id: 1,
                    text: "Use a Business Home Equity Line of Credit to borrow against  the equity in your personal residence to help finance your business."
                  },
                  {
                    id: 2,
                    text: "Unsecured lines of credit are available up to $50,000."
                  },
                  {
                    id: 3,
                    text: "Quick decisions on unsecured lines up to $10,000 are available with less documentation required."
                  },
                  {
                    id: 4,
                    text: "Rely on us for a common-sense decision to your line of credit application, based on your business' assets."
                  },
                  {
                    id: 5,
                    text: "Combine a line of credit with our Business Sweep Accounts. We can automatically transfer funds daily between your operating account and related line of credit to efficiently manage cash resources, providing you maximum flexibility."
                  }
                ]
              }
            },
            {
              id: 4,
              button: { ...ctaButtonData }
            }
          ]
        }
      },
      {
        id: 2,
        colClass: "col-sm-12 col-md-6",
        content: {
          components: [
            {
              id: 1,
              image: {
                imgVariable: imgData.linesOfCreditImage.childImageSharp.gatsbyImageData,
                altText: "Woman holding a digital tablet and looking at potted flowers in a greenhouse",
                class: "border-radius-12"
              }
            }
          ]
        }
      }
    ]
  };

  const commercialRealEstateData = {
    sectionClass: "bg-white",
    sectionId: "commercial-real-estate-section",
    columns: [
      {
        id: 1,
        colClass: "col-sm-12 col-md-6 mb-4 mb-md-0",
        content: {
          components: [
            {
              id: 1,
              image: {
                imgVariable: imgData.commercialRealEstateImage.childImageSharp.gatsbyImageData,
                altText: "Two managers at work in the business office",
                class: "border-radius-12"
              }
            }
          ]
        }
      },
      {
        id: 2,
        colClass: "col-sm-12 col-md-6",
        content: {
          components: [
            {
              id: 1,
              heading: {
                text: "Commercial Real Estate Financing"
              }
            },
            {
              id: 2,
              paragraph: {
                text: "If you need to purchase or refinance your commercial property, we offer a number of options to fit your needs."
              }
            },
            {
              id: 3,
              list: {
                items: [
                  {
                    id: 1,
                    text: "Own the space where you do business. Ask us about financing real estate purchases."
                  },
                  {
                    id: 2,
                    text: "Save money by refinancing your current property."
                  },
                  {
                    id: 3,
                    text: "Outgrown your space?  Ask us about financing options to help you expand your business."
                  }
                ]
              }
            },
            {
              id: 4,
              button: { ...ctaButtonData }
            }
          ]
        }
      }
    ]
  };

  const businessTermLoansData = {
    sectionClass: "bg-light",
    sectionId: "business-term-loans-section",
    columns: [
      {
        id: 1,
        colClass: "col-sm-12 col-md-6 mb-4 mb-md-0",
        content: {
          components: [
            {
              id: 1,
              heading: {
                text: "Business Term Loans"
              }
            },
            {
              id: 2,
              paragraph: {
                text: "If your business needs to purchase equipment, make capital improvements, or finance future expansion, Business Term Loans are the ideal solution."
              }
            },
            {
              id: 3,
              list: {
                items: [
                  {
                    id: 1,
                    text: "Business Term Loans typically have terms of 1-7 years."
                  },
                  {
                    id: 2,
                    text: "We tailor the loan to fit your cash flow."
                  }
                ]
              }
            },
            {
              id: 4,
              button: { ...ctaButtonData }
            }
          ]
        }
      },
      {
        id: 2,
        colClass: "col-sm-12 col-md-6",
        content: {
          components: [
            {
              id: 1,
              image: {
                imgVariable: imgData.businessTermLoansImage.childImageSharp.gatsbyImageData,
                altText: "Smiling chief standing in auto park and holding tablet",
                class: "border-radius-12"
              }
            }
          ]
        }
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="credit-solutions-section" className="container">
        <h1>Credit Solutions</h1>
        <p>
          For more than 100 years, we've taken a personal approach to protecting your long-term interests. Consider our
          business bankers your own trusted advisors, dedicated to working with you to develop a straight-forward
          financing strategy that's right for you. After all, we understand that our success depends on yours.
        </p>
      </section>

      <TwoColumn {...linesOfCreditData} />
      <TwoColumn {...commercialRealEstateData} />
      <TwoColumn {...businessTermLoansData} />
    </SecondaryLanding>
  );
};

export default BusinessCreditSolutions;
